import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperSocialSituations'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextStepper = makeShortcode("TextStepper");
const ImageGrid = makeShortcode("ImageGrid");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "a-range-of-social-situations"
    }}>{`A range of social situations`}</h1>
    <p>{`Encourage your teenager to be involved in a range of social situations. Some good social activities include:`}</p>
    <TextStepper id="textStepperSocialSituations" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: 'Language classes'
    }, {
      text: 'Singing in a choir'
    }, {
      text: 'Playing music in a band'
    }, {
      text: 'Debating team'
    }, {
      text: 'Paid and unpaid jobs'
    }, {
      text: 'Martial arts'
    }, {
      text: 'Team Sport (soccer, netball, cricket, swimming team, football, basketball, hockey, tennis, volleyball… the list is endless)',
      long: true
    }, {
      text: 'Athletics Club'
    }, {
      text: 'Scouts'
    }, {
      text: 'Cadets'
    }, {
      text: 'Guides'
    }, {
      text: 'Drama'
    }, {
      text: 'Gymnastics'
    }, {
      text: 'Dance'
    }]} mdxType="TextStepper" />
    <p>{`Of course, your teenager does not need to do `}<em parentName="p">{`all`}</em>{` these activities at any point in time (and neither should they)! The key is to encourage them to try different things, so that they get to experience different social situations.`}</p>
    <ImageGrid list={[{
      src: '/images/m6/29-01.svg'
    }, {
      src: '/images/m6/29-02.svg'
    }]} mdxType="ImageGrid" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      